<template>
  <div class="open-animation">
    <!-- <template v-if="videoShow"> -->
    <div class="open-screen" v-if="videoShow">
      <div class="bg-box" :style="{'--width':width}" :class="{'an':startAnimation}">
        <img src="https://img.cagibrand.com/yszsg/images/enter_bg2.jpg" alt="" class="bg-img" :class="{'full':isFull}" ref="img">
        <!-- <img src="../../static/img/openAnimation/enter_bg.jpg" alt="" class="bg-img" :class="{'full':isFull}"> -->
      </div>
      <div class="enter">
        <img src="../../static/img/openAnimation/btn.png" alt="" class="btn" @click="enter(true)">
        <div class="en-text" @click="enter(false)">Enter</div>
      </div>
    </div>
    <!-- </template>
    <template v-else> -->
    <div v-show="!videoShow">
      <div class="skip-btn" @click="skip()">跳过</div>
      <div class="home-video">
        <video src="https://img.cagibrand.com/yszsg/multi/video_05.mp4" class="video" preload="auto" @ended="ended" ref="video" controlsList="nodownload" oncontextmenu="return(false);">
          您的浏览器不支持 video 标签。
        </video>
        <!-- <div class="btn-box">
          <img src="../../static/img/logo.png" class="logo">
          <div>
            <img src="../../static/img/home/ch-btn.png" alt="" class="btn-img" @click="selL('1422454440404713472')">
            <img src="../../static/img/home/en-btn.png" alt="" class="btn-img" @click="selL('1422454502115508224')">
          </div>
        </div> -->
        <!-- <audio preload="auto" loop id="audio" muted src="https://img.mzszxc.com/mzsz/img/website/brand.mp3"></audio> -->
      </div>
    </div>
    <!-- </template> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoHid: true, // video动画
      videoShow: true,
      width: '',
      isFull: false,
      startAnimation: false
    }
  },
  created() {
    // if (sessionStorage.getItem('played')) {
    //   this.$router.replace({
    //     path: '/home'
    //   })
    // }
  },
  mounted() {
    setTimeout(() => {
      console.log(parseInt(this.$refs.img.offsetWidth))
      let maxW = parseInt(this.$refs.img.offsetWidth)
      let w = maxW - document.documentElement.clientWidth
      if (w > 0) {
        console.log(document.documentElement.clientWidth, w)
        this.width = w + 'px'
        this.startAnimation = true
      } else {
        this.isFull = true
      }
    }, 100)
    // 自动播放音乐效果
    // document.addEventListener('touchstart', this.audioAutoPlay, false)
    // document.addEventListener('WeixinJSBridgeReady', this.audioAutoPlay, false)
    // let oAudio = document.querySelector("#audio")
    // oAudio.play()
    // oAudio.onended = function () {//播放完毕，重新循环播放
    //   oAudio.load()
    //   oAudio.play()
    // }
  },
  methods: {
    // audioAutoPlay() {
    //   let audio = document.getElementById('audio')
    //   audio.play()
    //   document.removeEventListener('touchstart', this.audioAutoPlay)
    // },
    enter(flag) {
      this.videoShow = false
      let player = this.$refs.video
      player.play()
      let lang = 'ch'
      let siteId = '1422454440404713472'
      if (!flag) {
        lang = 'en'
        siteId = '1422454502115508224'
        this.$store.commit('changeLanguage', false)
      } else {
        this.$store.commit('changeLanguage', true)
      }
      localStorage.setItem('locale', lang)
      this.$i18n.locale = lang
      localStorage.setItem('siteId', siteId)
    },
    // 视频播放结束
    ended() {
      // this.videoHid = false
      sessionStorage.setItem('played', true)
      setTimeout(() => {
        this.$router.replace({
          path: '/home'
        })
      }, 1000)
    },
    skip() {
      // this.videoShow = false
      sessionStorage.setItem('played', true)
      this.$router.replace({
        path: '/home'
      })
    },
    // 选择语言
    selL(siteId) {
      localStorage.setItem('siteId', siteId)
      this.$router.replace({
        path: '/home'
      })
    }
  }
}
</script>

<style lang="scss">
@keyframes move {
  0% {
    transform: translateX(0);
  }

  100% {
    // transform: translateX(2315px);
    transform: translateX(var(--width));
  }
}
.open-animation {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .open-screen {
    flex: 1;
    overflow: hidden;
    display: flex;
    position: relative;
    justify-content: flex-end;
    .bg-box {
      display: flex;
      flex: 1;
      // animation: move 3s linear forwards;
    }
    .an {
      animation: move 15s linear forwards;
    }
    .bg-img {
      // animation: move 3s linear infinite;
      // height: 100%;
      // width: auto;
    }
    .full {
      width: 100%;
    }
    .enter {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -112px 0 0 -28px;
      cursor: pointer;
      .btn {
        width: 56px;
        height: 225px;
      }
      .en-text {
        font-size: 20px;
        line-height: 20px;
        margin: 20px 0 0 0;
        letter-spacing: 1px;
        color: #fff;
      }
    }
  }
  .skip-btn {
    position: absolute;
    width: 80px;
    height: 40px;
    right: 20px;
    top: 1.5vh;
    color: #fff;
    border: 1px solid #fff;
    z-index: 9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 14px;
    line-height: 14px;
  }
  .home-video {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    .video {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .video-hid {
      animation: videoShow 0.8s ease-in forwards;
    }
    .btn-box {
      background: url('../../static/img/home/video-bg.jpg') no-repeat center;
      background-size: 100% 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo {
        width: 404px;
      }
      .btn-img {
        width: 246px;
        height: 60px;
        margin: 30px 25px 0;
        cursor: pointer;
      }
    }
  }
}
</style>